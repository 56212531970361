// Mixins
@mixin linear-gradient($color, $deg) {
  background: linear-gradient($deg, rgba(0, 0, 0, 0), $color 200%);
}

// Font sizes
$very_small_font_size: 9px;
$small_font_size: 14px;
$standard_font_size: 24px;
$big_display_font_size: 32px;

// Fonts
@mixin font-default() {
  font-family: 'Roboto', sans-serif;
}

@mixin font-label() {
  font-family: 'Oswald', 'Arial', cursive;
}

@mixin hacker-border() {
  border: 1px solid $pale_goldenrod;
}

// Colors
$light_color: #fff;
$light_slate: lighten(#353541, 50%);
$slate: lighten(#353541, 5%);
$dark_slate: #25252d;
$really_dark_slate: #0c0c0f;
$pastel_background: rgb(196, 196, 207);
$html: #baffc9;
$css: #baffc9;
$js: #ffffba;
$php: #ffb3ba;
$laravel: #ffdfba;
$wordpress: #bae1ff;
$link_color: #6666FF;

$gunmetal: #28323C;
$dark_gunmetal: #22262B;
$pale_goldenrod: #E2F4AD;
// Dimensions
$std_pad: 20px;
$box_margin: 15px;
$box_width: 100%;
$small_px: 5px;
$radius: 0px;