@import "variables";

body {
  @include font-default();
  background-color: $gunmetal;
}

#drink-holder {
  display: flex;
  align-items: center;
  justify-content: center;

  #nerd-image {
    z-index: 2;
    max-width: 75px;
  }
}

.box {
  margin: 15px;
}

.hacker_theme {
  background-color: $dark_gunmetal;
  color: $pale_goldenrod;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    color: $pale_goldenrod;
  }
}

.input {
  border-radius: $radius;
  @include hacker-border();
  background-color: $dark_gunmetal;
  color: $pale_goldenrod;

  &:focus,
  &:hover {
    border-color: $pale_goldenrod;
  }

  &::placeholder {
    color: $pale_goldenrod;
  }
}

.tools {
  color: $pale_goldenrod;

  p {
    display: inline-block;
  }

  .sub-tools {
    margin: 0;
    margin-left: 5px;
    display: inline-block;

    li {
      font-size: 11px;
      display: inline-block;
      list-style-type: none;

      &:not(:first-child) {
        margin-left: 5px;
      }

      &:not(:last-child):after {
        content: ', ';
      }

      &:first-child:before {
        content: '(';
      }

      &:last-child:after {
        content: ')';
      }
    }
  }
}

.skills {
  background-color: $dark_gunmetal;
  @include hacker-border();
  border-radius: $radius;
}

h2.heading {
  border-bottom: 1px solid $light_slate;
  color: $light_color;
  font-weight: lighter;
  letter-spacing: $std_pad/2;
  line-height: 50px;
  margin: $std_pad 0;
  text-align: center;
  text-transform: uppercase;
}

#filter-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .filter-grid-item {
    margin-bottom: $std_pad;
    padding: 0;
    position: relative;
    flex-basis: 100%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      .display-face .content .title {
        height: 200px;
      }
    }

    @media (min-width: 600px) {
      flex-basis: calc((100% - (#{$box_margin} * 4)) / 2);

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        .display-face .content .title {
          height: 200px;
        }
      }
    }

    @media (min-width: 900px) {
      flex-basis: calc((100% - (#{$box_margin} * 6)) / 3);

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        .display-face .content .title {
          height: 200px;
        }
      }
    }

    @media (min-width: 1200px) {
      flex-basis: calc((100% - (#{$box_margin} * 8)) / 4);

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        .display-face .content .title {
          height: 200px;
        }
      }
    }

    .display-face {
      @include hacker-border();
      border-radius: $radius;
      box-sizing: border-box;
      padding: 0;
      display: block;
      overflow: hidden;
      position: relative;

      .content {
        position: relative;
        top: 0;
        transition: top 0.33s ease;

        .title {
          background-size: cover;
          background-position: top;
          cursor: pointer;
          height: 200px;
          margin-bottom: 0;
          position: relative;
          transition: height 0.5s cubic-bezier(0, 1, 0, 1);
        }


        .bottom-details {
          background-color: $gunmetal;
          width: 100%;

          p {
            color: $pale_goldenrod;
            display: inline-block;
            font-size: $very_small_font_size;
            margin: 0;
            padding: $std_pad/3;

            span {
              font-style: italic;
            }
          }

          a.open {
            background-color: transparentize($light_color, 0.95);
            color: $light_color;
            display: inline-block;
            float: right;
            font-size: $very_small_font_size;
            padding: $std_pad/3;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
              background-color: transparentize($light_color, 0.75);
            }
          }
        }
      }
    }

    .description-box {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }

    &.open {
      .display-face .content .title {
        height: 200px;
        transition: height 0.5s cubic-bezier(0, 1, 0, 1);
      }

      .description-box {
        max-height: 900px;
        transition: max-height 0.5s ease;
      }
    }
  }
}

// Modal
.project-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.project-modal {
  @include hacker-border();
  border-radius: $radius;
  max-width: 800px;
  max-height: 80%;
  overflow: scroll;
  z-index: 4;
}

.project-template {

  h1,
  h2,
  h3,
  h4 {
    @include font-label();
    text-align: center;
  }

  img {
    @include hacker-border();
    display: block;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: $std_pad;
    width: 100%;
  }

  .img-gallery {
    display: flex;
    max-width: 600px;
    justify-content: space-between;

    .img-gallery-item {
      flex-grow: 1;
      flex-shrink: 1;

      &:not(:last-child) {
        margin-right: $small_px;
      }

      img {
        margin-bottom: $small_px;
      }
    }
  }

  pre {
    background-color: lightgrey;
    border-radius: 3px;
    overflow-x: auto;
    white-space: pre-wrap;
    padding: 10px 15px;
  }

  .project-open {
    background-color: $gunmetal;
    bottom: -20px;
    color: $pale_goldenrod;
    display: block;
    margin: 0 -20px -20px -20px;
    padding: 15px;
    position: sticky;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }
}